import styled from 'styled-components';
import { Button, Typography } from '@/components';
import { TextStyles } from '@/constants';
import Check from '../../../public/images/icons/check.svg';

export const CardHeaderWrapper = styled.div`
  width: 100%;
  height: 50px;
  background-color: ${({ theme }) => theme.colors.darkBlue};
  padding: ${({ theme }) => `${theme.spacing[12]} ${theme.spacing[24]}}`};
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CardHeader = styled(Typography).attrs({
  variant: TextStyles['Heading 3'],
})`
  text-align: center;
  color: ${({ theme }) => theme.colors.highEmphasisWhite};
  background-color: ${({ theme }) => theme.colors.darkBlue};
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  word-break: break-word;
`;

export const CardTitle = styled(Typography).attrs({
  variant: TextStyles['Heading 2'],
})`
  text-align: center;
  color: ${({ theme }) => theme.colors.highEmphasisBlack};
  margin-bottom: ${({ theme }) => theme.spacing[8]};
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  word-break: break-word;
`;

export const CardSubTitle = styled(Typography).attrs({
  variant: TextStyles['Body Text Large'],
})`
  color: ${({ theme }) => theme.colors.mediumEmphasisBlack};
  word-break: break-word;
  text-align: center;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
`;

export const CardDivider = styled.div`
  height: 1.5px;
  width: 100%;
  background: #ebeced;
  margin: ${({ theme }) => theme.spacing[24]} 0;
`;

export const CardTopSection = styled.div`
  height: 82px;
`;

export const CardContentList = styled.div`
  flex: 1;
`;

export const CheckIcon = styled(Check)`
  width: 24px;
  height: 24px;
  margin-right: ${({ theme }) => theme.spacing[16]};

  path {
    fill: ${({ theme }) => theme.colors.blue};
  }
`;

export const CardValue = styled(Typography).attrs({
  variant: TextStyles['Body Text Large'],
})`
  flex: 1;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  word-break: break-all;
  color: ${({ theme }) => theme.colors.mediumEmphasisBlack};
  word-break: break-word;
`;

export const CardContentItem = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;

  :not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing[16]};
  }
`;

export const ContentCardContent = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  padding: ${({ theme }) => `${theme.spacing[24]}`};
  flex: 1;
`;

export const CardWrapper = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.04),
    0px 16px 24px rgba(96, 97, 112, 0.16);
  width: 100%;
  background-color: ${({ theme }) => theme.colors.highEmphasisWhite};
  margin-bottom: ${({ theme }) => theme.spacing[16]};

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    margin-bottom: ${({ theme }) => theme.spacing[32]};
    width: calc((100% - 48px) / 3);

    :not(:last-child) {
      margin-right: ${({ theme }) => theme.spacing[24]};
    }

    :nth-child(3n),
    :last-child {
      margin-right: 0 !important;
    }
  }
`;

export const ContentCardListWrapper = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: -${({ theme }) => theme.spacing[16]};

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    & > div:not(:first-child) {
      margin-right: ${({ theme }) => theme.spacing[24]};
    }
    margin-bottom: -${({ theme }) => theme.spacing[32]};
  }
`;

export const CardButton = styled(Button)`
  margin-top: ${({ theme }) => theme.spacing[32]};
  padding: ${({ theme }) => theme.spacing[16]} 0;
  width: 100%;
`;
export const ContentCardContainer = styled.div``;
