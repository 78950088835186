import type { FC } from 'react';
import { ButtonVariant, type IContentCard } from '@/interfaces';
import {
  CheckIcon,
  ContentCardContainer,
  ContentCardContent,
  CardContentItem,
  ContentCardListWrapper,
  CardDivider,
  CardHeader,
  CardSubTitle,
  CardTitle,
  CardWrapper,
  CardValue,
  CardHeaderWrapper,
  CardTopSection,
  CardContentList,
  CardButton,
} from './ContentCard.styles';

interface IContentCardProps {
  data: IContentCard[];
}

const ContentCard: FC<IContentCardProps> = ({ data }) => {
  return (
    <ContentCardContainer>
      <ContentCardListWrapper>
        {data.map(({ header, title, subTitle, contentList, button, sys }: IContentCard) => {
          return (
            <CardWrapper key={sys.id}>
              <CardHeaderWrapper>
                <CardHeader>{header}</CardHeader>
              </CardHeaderWrapper>
              <ContentCardContent>
                <CardTopSection>
                  <CardTitle>{title}</CardTitle>
                  <CardSubTitle>{subTitle}</CardSubTitle>
                </CardTopSection>
                <CardDivider />
                <CardContentList>
                  {contentList.map((content: string, index: number) => (
                    <CardContentItem key={index}>
                      <CheckIcon />
                      <CardValue>{content}</CardValue>
                    </CardContentItem>
                  ))}
                </CardContentList>
                {button && (
                  // TODO: Hardcode this button is Outlined for now.
                  // Button type should be dynamic.
                  <CardButton {...button} variant={ButtonVariant.Outlined} />
                )}
              </ContentCardContent>
            </CardWrapper>
          );
        })}
      </ContentCardListWrapper>
    </ContentCardContainer>
  );
};

export default ContentCard;
